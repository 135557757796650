import { experiencesQuery } from '../gql/experiences'
import { useQuery, useResult } from '@vue/apollo-composable'

export default function useProjects () {
    function getExperiences () {
        const { result, loading } = useQuery(experiencesQuery)
        const experiences = useResult(result)
        return {
            experiences,
            experiencesLoading: loading
        }
    }

    return {
        getExperiences
    }
}