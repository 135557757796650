import { skillsQuery } from '../gql/skills'
import { useQuery, useResult } from '@vue/apollo-composable'

export default function useProjects () {
    function getSkills () {
        const { loading, result } = useQuery(skillsQuery)
        const skills = useResult(result)
        return { skills, skillsLoading: loading }
    }

    return {
        // getProjects,
        getSkills
    }
}