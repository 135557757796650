<template>
    <div className="Experience">
        <div className="header">
            <h4 className="title jobTitle">{{ title }}</h4>
            <div className="duration">
                <div className="duration--from">From: {{ duration.from }}</div>
                <br/>
                <div className="duration--to">To: {{ duration.to || 'still going !' }}</div>
            </div>
        </div>
        <div className="separator"></div>
        <div className="description" v-html="text.replace(/\\n/g, '<br/>')"></div>
    </div>
</template>

<script>
export default {
    name: 'Experience',
    props: {
        title: {
            type: String,
            required: true
        },
        duration: {
            type: Object,
            default: () => ({ from: new Date() })
        },
        text: {
            ype: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.Experience {
  .jobTitle {
    font-size: 1.7em;
    text-align: center;
    width: 67%;
  }
  .description {
    text-align: center;
    font-size: 1.2em;
    white-space: pre-line;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-device-width: 480px) {
      flex-direction: column;
      text-align: center;
    }
  }
  .duration {
    width: 20%;
    &--from {
      @media (max-device-width: 480px) {
        display: inline;
      }
    }
    &--to {}
    @media (max-device-width: 480px) {
      width: 100%;
      margin-bottom: 3vh;
    }
  }
  .separator {
    width: 100%;
    border-bottom-style: solid;
    border-width: thin;
    margin-bottom: 5%;
    color: #DDD;
    @media (max-device-width: 480px) {
      width: 90%;
      border-width: medium;
      margin-left: 5%;
    }
  }
}
</style>