<template>
  <div class="Skills" ref="rootElement">
    <div className="Skills__title">{{ title.replace('sills', 'skills') }}</div>
    <transition :name="transitionName"  
                @after-enter="trigger = true">
        <table className="Skills__table"
               v-if="technos && appear">
            <tbody>
            <tr>
                <td></td>
                <td className="table__legend">
                <div className="threshold table__colTitle">Used it</div>
                <div className="threshold table__colTitle">Experienced</div>
                <div className="threshold table__colTitle">Confirmed</div>
                <div className="threshold table__colTitle">Not bad</div>
                </td>
            </tr>
            <tr v-for="(techno, index) in technos" 
                :key="index">
                <td class="table__item">{{ techno.techno }}</td>
                <td className="table__item">
                <div className="progress">
                    <div className="bar" :style="{ width: trigger ? techno.value + '%' : 0, transitionDelay: `0.${index%3}s`, ...barStyle }"></div>
                </div>
                </td>
            </tr>
            </tbody>
        </table>
    </transition>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
export default {
    name: 'Skills',
    props: {
        title: {
            type: String,
            required: true
        },
        technos: {
            type: Object,
            default: null
        },
        barStyle: {
            type: Object,
            default: () => ({backgroundColor: 'aliceBlue'})
        },
        transitionName: {
            type: String,
            default: 'slide'
        }
    },
    setup () {
        const trigger = ref(false)
        const appear = ref(false)
        const rootElement = ref(null)

        const observer = new IntersectionObserver(handleIntersect, { threshold: 0.7 })

        function handleIntersect (entries) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    observer.unobserve(rootElement.value)
                    appear.value = true
                }
            });
        }

        onMounted(() => {
            observer.observe(rootElement.value)
        })

        return {trigger, appear, rootElement}
    }
}
</script>

<style scoped lang="scss">
.Skills {
    min-height: 500px;

  .progress {
    width: 90%;
    background: #f9f9f9;
  }
  .bar {
    height: 25px;
    transition: width 1s linear;

    @media (min-width: 460px) {
      height: 40px;
    }
  }
  &__table {
    width: 100%;
    padding: 6vh 0 10vh;
    overflow: hidden;
    .table {
      &__item {
        width: 20%;

        @media (min-width: 460px) {
          width: 50%;
        }
      }
      &__legend {
        display: flex;
        justify-content: space-between;
        width: 90%;
        padding-bottom: 18px;
      }
      &__colTitle {
        transform: rotateZ(-50deg);

        @media (min-width: 460px) {
          transform: none;
        }
      }
    }
  }

  &__title {
      padding: 0 5vw;
      background: linear-gradient(141deg,#ff9125,#1fc8db 75%);
      text-align: start;
      color: #fff;
      font-size: 3em;
      font-variant: small-caps;

      @media (min-width: 460px) {
        padding: 0 30vw;
      }
  }
}

@media (max-device-width: 640px) {
  .Skills {
    .bar {
      transition-duration: 0.67s;
    }
  }
}
</style>