import gql from "graphql-tag"

export const skillsQuery = gql`
    query skills {
        skills {
            title
            sliderSide
            barStyle {
                backgroundColor
            }
            technos {
              techno
              value
            }
            category
        }
    }
`