import gql from "graphql-tag"

export const experiencesQuery = gql`
    query experiences {
        experiences {
            title
            logo
            text
            duration {
                from
                to
            }
        }
    }
`